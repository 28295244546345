import '@mdi/font/css/materialdesignicons.css'

import 'vuetify/styles'
import { createVuetify } from 'vuetify'

export default defineNuxtPlugin((app) => {
    const tg = window?.Telegram?.WebApp?.themeParams

    let theme

    if(tg) {
        theme = {
            defaultTheme: 'customTheme',
            themes: {
                customTheme: {
                    dark: false,
                    colors: {
                        background: tg.secondary_bg_color,
                        surface: tg.secondary_bg_color,
                        "on-surface": tg.text_color,
                        primary: tg.button_color,
                        "on-primary": tg.button_text_color,
                        error: '#F44336'
                    }
                }
            }
        }
    } else {
        theme = {
            defaultTheme: 'dark',
        }
    }

    const vuetify = createVuetify({
        theme: theme
    })

    app.vueApp.use(vuetify)
})