import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as _91id_93uSABYnFuPuMeta } from "/app/pages/suggests/[id].vue?macro=true";
import { default as draft_45_91id_93XUrJ8UR4CpMeta } from "/app/pages/suggests/draft-[id].vue?macro=true";
import { default as indexJB8EbsrOMTMeta } from "/app/pages/suggests/index.vue?macro=true";
import { default as newMNDW1QMRKyMeta } from "/app/pages/suggests/new.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "suggests-id",
    path: "/suggests/:id()",
    component: () => import("/app/pages/suggests/[id].vue")
  },
  {
    name: "suggests-draft-id",
    path: "/suggests/draft-:id()",
    component: () => import("/app/pages/suggests/draft-[id].vue")
  },
  {
    name: "suggests",
    path: "/suggests",
    component: () => import("/app/pages/suggests/index.vue")
  },
  {
    name: "suggests-new",
    path: "/suggests/new",
    component: () => import("/app/pages/suggests/new.vue")
  }
]