import {init} from "@tma.js/sdk";
import {getAuthToken} from "#build/imports";

export default function () {
    const authToken = useState<string | null>('authToken', () => null)
    const authAbilities = useState<string[]>('authAbilities', () => [])
    const authLoading = useState<boolean>('authLoading', () => true)

    const {initDataRaw, viewport} = init()

    viewport.expand()

    $api<ApiToken>("/api/getToken", {
        method: 'post',
        body: {
            telegramHash: initDataRaw
        }
    }).then((token) => {
        if(token.abilities.includes('user')) {
            authToken.value = token.token
            authAbilities.value = token.abilities
        }

        authLoading.value = false
    }).catch(() => {
        setTimeout(getAuthToken, 3000)
    })
}